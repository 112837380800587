<template>
  <div>
    <table id="memberListTable" class="w3-table-all">
      <thead>
        <tr>
          <th
            v-for="(k, index) in [
              'mbrnumber',
              'firstname',
              'lastname',
              'email',
              'cellphone',
              'homephone',
              'flight',
              'league',
            ]"
            :key="index"
            @click="sortTable(index)"
          >
            {{ k }}
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="(mem, index) in members" :key="index">
        <td
          v-for="(k, index) in [
            'mbrnumber',
            'firstname',
            'lastname',
            'email',
            'cellphone',
            'homephone',
            'flight',
            'league',
          ]"
          :key="index"
        >
          {{ mem[k] }}
        </td>
        <td>
          <button @click="editMember(mem['clubnumber'], index)">Edit</button>
        </td>
        <td>
          <button @click="deleteClubnumberMember(mem['clubnumber'], index)">
            Del
          </button>
        </td>
      </tr>
    </table>
    <MemberEdit
      v-if="showEdit"
      @cancelEdit="cancelEdit"
      @commitEdit="commitEdit"
      :memberNumber="memberNumber"
    />
  </div>
</template>

<script>
import { getMembers, changeMember } from "@/store.js";
import { ref, computed } from "vue";
import MemberEdit from "@/components/MemberEdit";
import { deleteMember } from "@/store.js";

export default {
  components: {
    MemberEdit,
  },
  setup() {
    const showEdit = ref(false);
    const memberNumber = ref("");

    function cancelEdit() {
      showEdit.value = false;
    }

    function commitEdit(m) {
      changeMember(m);
      showEdit.value = false;
    }

    function editMember(clubnumber) {
    console.log("editing game", clubnumber);
      memberNumber.value = clubnumber;
      showEdit.value = true;
    }

    function deleteClubnumberMember(clubnumber, index) {
      deleteMember(clubnumber, index);
      showEdit.value = false;
    }

    function sortTable(n) {
      var table,
        rows,
        switching,
        i,
        x,
        y,
        shouldSwitch,
        dir,
        switchcount = 0;
      table = document.getElementById("memberListTable");
      switching = true;
      // Set the sorting direction to ascending:
      dir = "asc";
      /* Make a loop that will continue until no switching has been done: */
      while (switching) {
        // Start by saying: no switching is done:
        switching = false;
        rows = table.rows;
        /* Loop through all table rows (except the first, which contains table headers): */
        for (i = 1; i < rows.length - 1; i++) {
          // Start by saying there should be no switching:
          shouldSwitch = false;
          /* Get the two elements you want to compare, one from current row and one from the next: */
          x = rows[i].getElementsByTagName("TD")[n];
          y = rows[i + 1].getElementsByTagName("TD")[n];
          /* Check if the two rows should switch place, based on the direction, asc or desc: */
          if (dir == "asc") {
            if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
              // If so, mark as a switch and break the loop:
              shouldSwitch = true;
              break;
            }
          } else if (dir == "desc") {
            if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
              // If so, mark as a switch and break the loop:
              shouldSwitch = true;
              break;
            }
          }
        }
        if (shouldSwitch) {
          /* If a switch has been marked, make the switch and mark that a switch has been done: */
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
          // Each time a switch is done, increase this count by 1:
          switchcount++;
        } else {
          /* If no switching has been done AND the direction is "asc", 
          set the direction to "desc" and run the while loop again. */
          if (switchcount == 0 && dir == "asc") {
            dir = "desc";
            switching = true;
          }
        }
      }
    }

    return {
      members: computed(() => getMembers.value),
      showEdit,
      memberNumber,
      cancelEdit,
      commitEdit,
      editMember,
      deleteClubnumberMember,
      sortTable,
    };
  },
};
</script>

<style  scoped>
tr:hover {
  background-color: #ffff99;
}
</style>