<template>
  <div class="home">
    <img src="@/assets/SteelLogo_BW.jpg" style="width: 300px; height: 300px" />
    <h1>Ladies League</h1>
  </div>
</template>


<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>

